<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title>Удалить маршрут</v-card-title>
      <v-divider/>
      <template v-if="loaded">
        <v-card-text class="pt-4 body-1" v-html="text">
        </v-card-text>
        <v-card-text class="pt-4 body-1">
          Вы действительно хотите удалить маршрут {{ selected.title }}?
        </v-card-text>
      </template>

      <div v-else class="align-center py-5" align="center">
        <v-progress-circular indeterminate color="primary"/>
      </div>

      <v-divider/>
      <v-card-actions>
        <v-btn
          @click="show = false"
          color="red"
          outlined
        >
          Отмена
        </v-btn>
        <v-spacer/>
        <v-btn
          class="text--white"
          color="primary"
          @click="deleteAll"
          :disabled="!loaded"
          :loading="loading"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  const PROJECT_STATUS_GUID = 'ed4762ee-ca91-4b52-89e8-1176fb0a296e';
  export default {
    name: 'DeleteRoute',
    props: {
      collection: {
        type: Object,
        required: true
      }
    },
    data: () => ({
        show: true,
        selected: null,
        loading: false,
        routeName: '',
        text: '',
        loaded: false,
    }),
    created() {
      this.selected = this.collection.master.selected;
      if (!this.selected) {
        this.terminate('Необходимо выбрать запись!');
        return;
      }
      if (this.selected.vcroutesVerstatus !== PROJECT_STATUS_GUID) {
        this.terminate('Действие доступно только для статуса «Проект»');
        return;
      }
      this.routeName = `${this.selected.vcroutesRoutecode} ${this.selected.vcroutesRoutename}`;

      this.loading = true;
      this.checkDependency();
      this.loading = false;
    },
    methods: {
      // Прерывание выполнения с закрытием диалога и сообщением типа "warning"
      // Необходимо для сокращения кол-ва кода
      terminate(message) {
        this.show = false;
        jet.msg({
          text: message,
          color: 'warning'
        });
      },
      // Проверка существования запланированных постановок
      async getPlannedTrips(scheduleId) {
        const requestTrips = {
          type: 'core-read',
          query: 'sin2:/v:41bbd718-7986-4796-83fb-f4f7805b1bf6/'
            + '?filter=eq(field(".route"),param("'
            + scheduleId
            + '", "id"))'
        };
        const trips = await jet.http.post(requestTrips);
        if (!trips.error) {
          return trips.result.data.length > 0;
        }
      },
      // Проверка всех зависимостей маршрута (Для предупреждения пользователя)
      async checkDependency() {
        //Получение всех версий выбранного маршрута
        const requestVersionsRoute = {
          type: 'core-read',
          query: 'sin2:/v:acd2f542-598c-4540-b46c-03375098d467/?filter=eq('
            + 'field(".verID"),param("'
            + this.selected.vcroutesVerid
            + '", "id"))&fields=.version,.ID,.verStatus'
        };
        const routes = [];
        const certificates = [];
        let versionsRoute = await jet.http.post(requestVersionsRoute);
        if (!versionsRoute.error) {
          versionsRoute = versionsRoute.result.data;
          for (const verRoute of versionsRoute) {
            //Дополнительно проверяем другие версии маршрута
            // Если попадается не "Проект", то запрещаем удалять
            if (verRoute[2] != PROJECT_STATUS_GUID) {
              this.terminate(
                'Действие доступно только для статуса «Проект». '
                + 'Одна из версий маршрута имеет другой статус'
              );
              return;
            } else {
              const routeID = verRoute[1];
              routes.push(routeID);
              const requestCertificatesRoute = {
                type: 'core-read',
                query: 'sin2:/v:0e6241ee-2dd5-4860-89ba-0324c0f0e7fb/'
                  + '?filter=eq(field(".servicedRouteID.route"),param("'
                  + routeID + '", "id"))&fields=.ID,.stateID.Name'
              };
              let certificatesRoute = await jet.http.post(requestCertificatesRoute);
              if (!certificatesRoute.error) {
                certificatesRoute = certificatesRoute.result.data;
                let certificateIssued = false;
                for (const certRoute of certificatesRoute) {
                  if (certRoute[0] === 'Новый') {
                    certificates.push(certRoute[1]);
                  } else {
                    certificateIssued = true;
                  }
                }
                if (certificateIssued) {
                  this.terminate('Маршрут не может быть удален '
                    + 'т. к. по нему выдано свидетельство'
                  );
                  return;
                }
              }
            }
          }
        }
        // Если все версии - проектные и можно удалять, то выполняется проверка
        // наличия расписаний, обслуж.маршрутов, договоров, тарификации, постановок
        if (routes.length > 0) {
          let serviceExist = false;
          let scheduleExist = false;
          let contractExist = false;
          let tripsExist = false;
          let tariffExist = false;
          for (const currentRouteID of routes) {
            //Расписание
            const requestSchedule = {
              type: 'core-read',
              query: 'sin2:/v:9622d81a-5411-4350-a324-a21c7604b261/?filter=eq(field(".'
                + 'route"),param("' + currentRouteID + '", "id"))&fields=.version,.ID'
            };
            let schedules = await jet.http.post(requestSchedule);
            if (!schedules.error) {
              schedules = schedules.result.data;
              if (schedules.length > 0) {
                scheduleExist = true;
                for (const schedule of schedules) {
                  const scheduleID = schedule[1];
                  if (tripsExist === false) {
                    const res = this.getPlannedTrips(scheduleID);
                    if (res === true) {
                      tripsExist = true;
                    }
                  }
                }
              }
            }
            //Обслуживаемые маршруты
            const requestServiceRoutes = {
              type: 'core-read',
              query: 'sin2:/v:e8fd22eb-f901-42af-874f-2bf5fe6a78f4/'
                +'?filter=eq(field(".route"),param("' + currentRouteID
                + '", "id"))&fields=.carrier.orgID.shortName'
            };
            let serviceRoutes = await jet.http.post(requestServiceRoutes);
            if (!serviceRoutes.error) {
              serviceRoutes = serviceRoutes.result.data;
              if (serviceRoutes.length > 0) {
                serviceExist = true;
                for (const serviceRoute of serviceRoutes) {
                  //Договор
                  const requestContract = {
                    type: 'core-read',
                    query: 'sin2:/v:9a5ccd37-dbfb-4a8b-a47a-5712b4dd10a6/'
                      + '?filter=eq(field(".routeID"),param("'
                      + serviceRoute[14] + '", "id"))'
                      + '&fields=.contractID.docNum,.startContractDt,.ID'
                  };
                  let contract = await jet.http.post(requestContract);
                  if (!contract.error) {
                    contract = contract.result.data;
                    if (contract.length > 0) {
                      contractExist = true;
                    }
                  }
                }
              }
            }
            //Тарификация
            const requestTariff = {
              type: 'core-read',
              query: 'sin2:/v:4d5f84e7-80f4-4644-b947-192239838d0b/?filter='
                + 'eq(field(".route"),param("' + currentRouteID
                + '", "id"))&fields=.ID'
            };
            let tariff = await jet.http.post(requestTariff);
            if (!tariff.error) {
              tariff = tariff.result.data;
              if (tariff.length > 0) {
                tariffExist = true;
              }
            }
          }

          const msg = [
            scheduleExist ? 'По маршруту создано расписание, которое будет удалено!' : null,
            contractExist ? 'Маршрут добавлен в договор, данный маршрут будет удален из договора.' : null,
            serviceExist ? 'По маршруту добавлены обслуживаемые маршруты, которые будут удалены.' : null,
            tariffExist ? 'По данному маршруту настроена тарификация, которая будет удалена.': null,
            tripsExist ? 'По маршруту существуют постановки на рейс, которые будут удалены.' : null,
            certificates.length > 0 ? 'По маршруту создано Свидетельство, которое будет удалено.' : null,
          ].filter(it => it !== null).join('<br/>');
          if (msg !== '') {
            this.text += msg;
          }
        }
        this.loaded = true;
      },
      // Удаление маршрута со всеми зависимостями
      async deleteAll() {
        this.loading = true;
        let optionsQuery = {
          type: 'query',
          query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.removeRouteVersion',
          params: {
            in_routeID: this.selected.vcroutesVerid,
            in_userID: this.selected._sec_attrsUserid,
            in_tenantID: this.selected._sec_attrsTenantid,
            in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
            in_type: 0
          }
        };
        try {
          const resp = await jet.http.post(optionsQuery);
          this.show = false;
          if (resp.error) {
            throw resp.error;
          }
          //this.collection.table().delete(this.selected.vcroutesId);
          this.collection.refresh(null);
          // const rows = this.collection.table().rows;
          // rows.map((r, i) => {
          //   if (r.vcroutesId === this.selected.vcroutesId) {
          //     rows.splice(i, 1);
          //   }
          // });
        } catch (e) {
          jet.msg({
            text: 'Не удалось удалить маршрут',
            color: 'warning'
          });
          console.error('DeleteRoute: deleteAll()', e);
          return;
        }
        jet.msg({
          text: `Маршрут № ${this.routeName} успешно удалён!`
        });
        this.loading = false;
      }
    }
  };
</script>
